import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/* 
 source image dimensions
 width: 1214
 height: 1319
 width to height ratio: 0.92 (width/height)
*/

const ActivitiesAndTrackingImage = ({ style = {} }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(
        relativePath: { eq: "feature-images/ActivitiesAndTracking.png" }
      ) {
        childImageSharp {
          fixed(width: 240, height: 260) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      fadeIn={false}
      fixed={data.image.childImageSharp.fixed}
      loading="eager"
    />
  )
}

export default ActivitiesAndTrackingImage
