import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/* 
 screen image dimensions
 width: 2650
 height: 5300
 width to height ratio: 0.5 (width/height)
*/

export const CourseQuestionImage = ({ style = {} }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "screen-shots/CourseQuestion.png" }) {
        childImageSharp {
          fixed(width: 150, height: 300) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      imageMedium: file(
        relativePath: { eq: "screen-shots/CourseQuestion.png" }
      ) {
        childImageSharp {
          fixed(width: 200, height: 400) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      imageLarge: file(
        relativePath: { eq: "screen-shots/CourseQuestion.png" }
      ) {
        childImageSharp {
          fixed(width: 250, height: 500) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)
  return (
    <>
      <div
        className="md:hidden flex items-center justify-center"
        style={{
          ...style,
        }}
      >
        <Img
          fadeIn={false}
          fixed={data.image.childImageSharp.fixed}
          loading="eager"
        />
      </div>
      <div
        className="hidden md:inline lg:hidden flex items-center justify-center"
        style={{
          ...style,
        }}
      >
        <Img
          fadeIn={false}
          fixed={data.imageMedium.childImageSharp.fixed}
          loading="eager"
        />
      </div>
      <div
        className="hidden lg:inline flex items-center justify-center"
        style={{
          ...style,
        }}
      >
        <Img
          fadeIn={false}
          fixed={data.imageLarge.childImageSharp.fixed}
          loading="eager"
        />
      </div>
    </>
  )
}

export default CourseQuestionImage
