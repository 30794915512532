import React from "react"

import SEO from "../components/Seo"
import PageWrapper from "../components/PageWrapper"
import Features from "../features/Features"

const FeaturesPage = ({ location }) => {
  return (
    <PageWrapper location={location} containMeNot>
      <SEO title="alvyApp - Features" />
      <Features />
    </PageWrapper>
  )
}

export default FeaturesPage
