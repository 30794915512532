import React from "react"

import {
  Heading1,
  Heading2,
  Heading3,
  Text,
  SmallText,
} from "../../components/common"
import PageHeaderImage from "../../components/PageHeaderImage"

import FeatureHeaderImage from "./FeatureHeaderImage"
import ActivitiesAndTrackingImage from "./ActivitiesAndTrackingImage"
import DiscoverImage from "./DiscoverImage"
import CreateYourOwnImage from "./CreateYourOwnImage"
import ShareWithFriendsImage from "./ShareWithFriendsImage"

import FeatureBlock from "./FeatureBlock"

const Feature = ({ Image, title, subtitle }) => {
  return (
    <div className="flex flex-col justify-center items-center py-4">
      <Image />
      <Heading3 className="uppercase pt-4 pb-2">{title}</Heading3>
      <SmallText colorClass="text-gray-500">{subtitle}</SmallText>
    </div>
  )
}

const Features = () => {
  return (
    <div>
      <div style={{ backgroundColor: "#d3f8ef" }}>
        <PageHeaderImage
          Image={FeatureHeaderImage}
          title="Features"
          headingClassName="mt-12 md:mt-40 ml-12 md:ml-48"
        />
      </div>
      <div className="bg-white">
        <div className="container mx-auto py-4 px-4">
          <div className="text-center py-6 md:py-12">
            <div className="md:hidden">
              <Heading1>
                Discover alvyApp's key features and how they help you achieve
                your goals
              </Heading1>
            </div>
            <div className="hidden md:inline">
              <Heading1 className="text-3xl leading-none">
                Discover alvyApp's key features
              </Heading1>
              <Heading1 className="text-3xl leading-none">
                and how they help you achieve your goals
              </Heading1>
            </div>
          </div>
          <div className="flex flex-wrap justify-around md:pt-12 pb-8">
            <Feature
              Image={ActivitiesAndTrackingImage}
              title="Daily Activities & Progress"
              subtitle="track your progress"
            />
            <Feature
              Image={DiscoverImage}
              title="Discover Courses"
              subtitle="from astronomy to zoology"
            />
            <Feature
              Image={CreateYourOwnImage}
              title="Create Your Own"
              subtitle="learn bespoke content"
            />
            <Feature
              Image={ShareWithFriendsImage}
              title="Share Your Courses"
              subtitle="with other alvyApp users"
            />
          </div>
        </div>
      </div>
      <FeatureBlock />
    </div>
  )
}

export default Features
